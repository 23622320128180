<template>
  <div class="flex h-screen">
    <aside class="w-64 border-r border-gray-50 bg-white px-4 py-3">
      <img v-if="currentStructure" :src="currentStructure.logo?.['@image_formats'].avatar_medium" class="mb-4 aspect-square h-10 w-10 rounded object-cover" :alt="currentStructure.name" />

      <div>
        <div class="mt-4">
          <router-link v-slot="{ isActive }" :to="{ name: 'calendar' }">
            <div :class="['flex-column flex aspect-square h-10 w-full place-items-center rounded-md px-3', isActive ? 'bg-primary-50 text-primary-600' : 'text-slate-700  hover:bg-slate-50']">
              <CalendarIcon class="h-4 w-4" /> <span class="ms-2">Agenda</span>
            </div>
          </router-link>
        </div>
        <div class="mt-1">
          <router-link v-slot="{ isActive }" :to="{ name: 'attempts' }">
            <div :class="['flex-column flex aspect-square h-10 w-full place-items-center rounded-md px-3', isActive ? 'bg-primary-50 text-primary-600' : 'text-slate-700 hover:bg-slate-50']">
              <PuzzlePieceIcon class="h-4 w-4" /> <span class="ms-2">Correction tentatives</span>
            </div>
          </router-link>
        </div>
        <div class="mt-1">
          <router-link v-slot="{ isActive }" :to="{ name: 'suggestions' }">
            <div :class="['flex-column flex aspect-square h-10 w-full place-items-center rounded-md px-3', isActive ? 'bg-primary-50 text-primary-600' : 'text-slate-700 hover:bg-slate-50']">
              <UserCircleIcon class="h-4 w-4" /> <span title="Suggestions pédagogique" class="ms-2 truncate">Suggestions pédago</span>
            </div>
          </router-link>
        </div>
        <div class="mt-1">
          <router-link v-slot="{ isActive }" :to="{ name: 'platform-suggestions' }">
            <div :class="['flex-column flex aspect-square h-10 w-full place-items-center rounded-md px-3', isActive ? 'bg-primary-50 text-primary-600' : 'text-slate-700 hover:bg-slate-50']">
              <UserCircleIcon class="h-4 w-4" /> <span class="ms-2">Suggestions plateforme</span>
            </div>
          </router-link>
        </div>
      </div>
    </aside>

    <div class="flex min-w-0 flex-1 flex-col">
      <header class="flex h-16 shrink-0 items-center justify-end border-b border-gray-50 bg-white px-6">
        <Menu v-if="currentUser" as="div" class="relative ml-3">
          <div>
            <MenuButton class="flex max-w-xs items-center rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span class="sr-only">Open user menu</span>
              <div class="flex items-center">
                <div>
                  <img class="inline-block h-10 w-10 rounded-full object-cover" height="40" width="40" :src="currentUser.avatar?.['@image_formats']?.['avatar_small']" :alt="`Avatar de ${currentUser.username}`" />
                </div>
                <div class="ml-3 text-start">
                  <p class="font-medium text-gray-700 group-hover:text-gray-900">
                    {{ currentUser.username }}
                  </p>
                  <p class="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                    {{ currentUser.email }}
                  </p>
                </div>
              </div>
            </MenuButton>
          </div>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems class="absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
              <MenuItem v-slot="{ active }" class="cursor-pointer">
                <a :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2  text-gray-700']" @click="toFront()">Retour à la plateforme</a>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>
      </header>

      <main class="flex-1 overflow-auto">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { trainingService } from "../services/api";
import { MutationTypes } from "../types/store";
import store from "../store";

export default defineComponent({
  beforeRouteEnter: async (to, from, next) => {
    const enrolledTrainings = await trainingService.getEnrolledTrainings();

    store.commit(MutationTypes.SET_ENROLLED_TRAININGS, enrolledTrainings);

    next();
  },
});
</script>

<script setup lang="ts">
import { PuzzlePieceIcon, UserCircleIcon, CalendarIcon } from "@heroicons/vue/24/outline";

import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

import { useCurrentUser } from "../hooks/useCurrentUser";
import { useCurrentStructure } from "../hooks/useCurrentStructure";

const currentUser = useCurrentUser();
const currentStructure = useCurrentStructure();

const toFront = () => {
  const { protocol } = window.location;

  window.location = `${protocol}//${window.env.DOMAIN}`;
};
</script>

